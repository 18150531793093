'use strict';

var ajax = require('./ajax'),
    util = require('./util'),
    _ = require('lodash'),
    imagesLoaded = require('imagesloaded');

const defaultDialogWidth = 'auto';

/**
 * jQuery UI Dialog Bootstrap Adapter
 */

$.extend($.ui.dialog.prototype.options.classes, {
    'ui-dialog': 'modal-dialog modal-content',
    'ui-dialog-titlebar': 'modal-header',
    'ui-dialog-title': 'modal-title',
    'ui-dialog-titlebar-close': 'close',
    'ui-dialog-content': 'modal-body',
    'ui-dialog-buttonpane': 'modal-footer'
});

/**
 * Extend the actual jQuery UI dialog prototype
 */

$.widget('ui.dialog', $.ui.dialog, {
    // Add new options
    options: {
        overlayTitle: false,
        showTitle: true,
        draggable: false,
        showTitlebar: true,
        showClosebutton: true,
        showBackArrow: false , // show back arrow instead of X close button
        showMobileBackArrow: false, // show back arrow in MOBILE ONLY
        mobilePreventFullscreen: false // set to true to prevent dialog from filling the entire viewport in mobile
    },
    // extend _create method to utilize our new options
    _create: function() {
        this._super();
        if (!this.options.showTitlebar) {
            this.uiDialogTitlebar.hide();
            this.options.showTitle = false;
        } else {
            if (this.options.overlayTitle === true) {
                this.uiDialog.addClass('overlay-titlebar');
            }
            if (this.options.showTitle === false || this.options.title == '' || this.options.title == ' ' || this.options.title == null) {
                this.uiDialogTitlebar.find('.ui-dialog-title').hide();
            }
            if (this.options.showClosebutton === false) {
                this.uiDialogTitlebar.find('.ui-dialog-titlebar-close').hide();
            }
            if (this.options.mobilePreventFullscreen === true) {
                this.uiDialog.addClass('mobile-non-fullscreen');
            }

            var closeIcon = 'close';
            var backIcon = 'backarrow';

            // Note: this overrides the closeText option
            this.uiDialogTitlebarClose.html(window.Iconography[closeIcon] + window.Iconography[backIcon]);
            if (this.options.showBackArrow === true) {
                this.uiDialog.addClass('mobile-backarrow desktop-backarrow');
            }
            if (this.options.showMobileBackArrow === true) {
                this.uiDialog.addClass('mobile-backarrow');
            }

        }
    },
    // allow title to contain HTML markup
    _title: function(title) {
        if (!this.options.title) {
            title.html('&#160;');
        } else {
            title.html(this.options.title);
        }
    }
});

// bind click-off to close modal
$(document).on('click', '.ui-widget-overlay', function () {
    // find all dialogs?
    var $dialog = $('.ui-dialog-content');
    $dialog.each(function() {
        if ($(this).parents('.no-close').length > 0) {
            return;
        }
        $(this).dialog('close');
        dialog.close(); // if the dialog.js object was used to create it.
    });
});

// generic event handler to close any dialog containing
// an element decorated with the "close-button" class
$(document).on('click','.close-button', function() {
    // find the dialog that contains this .close-button
    var $dialog = $(this).parents('.ui-dialog-content');
    $dialog.dialog('close');
    dialog.close(); // if the dialog.js object was used to create it.
});

$(document).on('click','.close-button-rebook', function() {
    // find the dialog that contains this .close-button
    var $dialog = $(this).parents('.ui-dialog-content');
    $dialog.dialog('close');
    dialog.close(); // if the dialog.js object was used to create it.
    location.reload();
});

/**
 * Create the dialog object for global use
 */
var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target;

        $target = util.getTarget(params.target);

        if ($target == null) {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
        }

        // create the dialog
        this.$container = $target;
        const options = _.merge({}, this.settings, params.options || {});
        this.$container.dialog(options);

        // Re-center the dialog when the viewport is resized
        $(window).off('resize.dialog').on('resize.dialog', function () {
            // prevent dialog from becoming full screen on viewport resize
            $target.dialog('option', 'width', params.options.width);

            if (util.mediaBreakpointUp('md')) {
                $target.dialog('option', 'position', {my: 'center', at: 'center', of: window});
            } else {
                $target.dialog('option', 'position', {my: 'top', at: 'top', of: window});
            }
        });

        // Set the position of the dialog correctly on load
        if (!util.mediaBreakpointUp('md')) {
            this.settings.position = {
                my: 'top',
                at: 'top',
                of: window
            };
        }
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html or params.content) or existing div to dialog (params.target)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     * @param {Object} params.target identifies an existing div in the DOM to dialog. The value can be an id selector (string) or an jquery object.
     */
    open: function (params) {
        // close any open dialog
        this.close();
        this.create(params);
        this.replace(params);
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) { return; }
        if (!util.getTarget(params.target)) {
            content = params.content || params.html;
            if (!content) {
                return;
            }
            // for reusable dialog containers, empty them out first, then inject content
            this.$container.empty().html(content);
        }

        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }

        if (!position) {
            position = this.settings.position;
        }

        imagesLoaded(this.$container).on('done', function () {
            this.$container.dialog('option', 'position', position);
        }.bind(this));

        $('#wrapper').addClass('open-modal')
            .attr('aria-hidden', 'true');

        $('.ui-dialog').focus();
        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                }.bind(this)
            });
        } else if (params.content || params.html || util.getTarget(params.target) !== null) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('close');
        $('#wrapper').removeClass('open-modal')
            .attr('aria-hidden', 'false');
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        resizable: false,
        width: defaultDialogWidth,
        close: function () {
            $(this).dialog('close');
            $('#wrapper').removeClass('open-modal')
                .attr('aria-hidden', 'false');
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        }
    },
    /**
     * Convenience error dialog
     * @param {String} errorMsg
     * @param {Object} [target] optional jQuery object target if not using default
     */
    error: function (errorMsg, target) {
        var config = {
            content: '<p>' + errorMsg + '</p><button class="btn btn-primary close-button">' + Resources.CLOSE + '</button>',
            options: {
                title: Resources.ERROR,
                dialogClass: 'error-modal'
            }
        };
        if (target) {
            config.target = target;
        }
        dialog.open(config);
    }
};

module.exports = dialog;
