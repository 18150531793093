'use strict';

var productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util');

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, focusElement) {
    if (!url || url === window.location.href) {
        return;
    }
    var wideTiles = $('.search-result-content').hasClass('wide-tiles') ? true : false;
    progress.show($('.tab-content'));
    $('.tab-pane.active').load(util.appendParamsToUrl(url, {'listv' : wideTiles, format : 'ajax'}), function () {
        productTile.init();
        progress.hide();

        // Focus the given element to make sure that focus is retained through the Ajax call
        if (focusElement) {
            var $focusElement = $(focusElement).eq(0);
            $focusElement.parents('.refinement-content').addClass('active');
            $focusElement.focus();
        }
    });
}

function initializeEvents() {
    productTile.init();

    var $main = $('.tab-content');
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a, .clear-all-refinements', function (e) {
        e.preventDefault();
        var focusElement = $(this).attr('id');

        if (focusElement) {
            focusElement = '#' + focusElement;
        }

        var queryParams = util.getQueryStringParams(util.getQueryString(this.href));
        var urlAfter, urlBefore;
        for (var key in queryParams) {
            var value = queryParams[key];
            if (value === 'id') {
                urlBefore = this.href;
                urlAfter = util.removeParamFromURL(urlBefore, key);
                urlAfter = util.removeParamFromURL(urlBefore, key.replace('prefn', 'prefv'));
            }
        }
        updateProductListing(urlAfter, focusElement);
    });
}

function initAddToFavourite () {
    $('body').on('click', '.add-to-favourite', function (e) {
        e.preventDefault();
        const $this = $(this);

        if (!$this.data('loggedcustomer')) {
            var $customURL = util.appendParamToURL(Urls.wishlistAdd, 'pid', $this.data('productid'));
            $('.login-container').attr('data-wishListTarget', $customURL);
            if (!$('.user-account').parent('.user-info').hasClass('active')) {
                $('.user-signin').trigger('click');
            }
            util.scrollBrowser('.user-signin');
            return;
        }

        var $clicked = ($this.hasClass('clicked')) ? true : false,
            $currentAction = ($clicked) ? Urls.wishlistRemove : Urls.wishlistAdd,
            $url = util.appendParamToURL($currentAction, 'pid', $this.data('productid'));

        $.ajax({
            url: $url,
            method: 'GET',
            dataType: 'json'
        }).done(function() {
            if ($clicked) {
                $this.removeClass('clicked')
                    .attr('title', Resources.ADD_TO_FAVOURITES)
                    .find('i').switchClass('fas', 'far', 100);
            } else {
                $this.addClass('clicked')
                    .attr('title', Resources.REMOVE_FROM_FAVOURITES)
                    .find('i').switchClass('far', 'fas',100);
            }
        })
    });
}

exports.initTile = function () {
    initializeEvents();
};

exports.initAddToFavourite = initAddToFavourite;
