'use strict';

const ajax = require('./ajax'),
    dialog = require('./dialog'),
    util = require('./util'),
    page = require('./page'),
    progress = require('./progress');

/**
 * @module
 * @desc Contains the custom promotion functionality for use throughout the site
 */

const Promotions = {

    /**
     * @function
     * @desc Initializes the custom promotion functionality
     */
    init: function () {
        initializeEvents();
    },

    /**
     * @function
     * @desc Retrieves the quantities for custom promotions from the basket
     * @param {String} ID - The ID of the promotion to get the quantities from (optional)
     * @returns
     */
    getBasketPromotionsQuantities: function (ID) {
        const productLineItems = window.ProductLineItems;
        const promotions = {};
        let productLineItem,
            lineItemPromos,
            qty;

        for (var i in productLineItems) {
            productLineItem = productLineItems[i];
            lineItemPromos = productLineItem.promoInfo;

            if (!lineItemPromos) {
                continue;
            }

            qty = productLineItem.quantity;

            for (var j in lineItemPromos) {
                const promo = lineItemPromos[j];

                if (promo.t === 'Combination') {
                    if (promotions[promo.ID] == null) {
                        promotions[promo.ID] = promo.groupReq.map(function (value, index) {
                            return (index === promo.groupNum) ? qty : 0;
                        });
                    } else {
                        promotions[promo.ID][promo.groupNum] += qty;
                    }
                } else {
                    promotions[promo.ID] = (promotions[promo.ID] == null) ? qty : promotions[promo.ID] + qty;
                }
            }
        }

        if (ID) {
            return promotions[ID];
        }

        return promotions;
    },

    /**
     * @function
     * @description Updates or builds UI for product promo nearness messaging
     */
    addPromoMessages: function() {
        const self = this;
        const promotionsModal = {};
        $('.mini-cart-product, .cart-row, .product-options, .product-offers').each(function(index, element) {
            const UUID = $(element).data('item-uuid');

            if (UUID && window.ProductLineItems[UUID]) {
                const promos = window.ProductLineItems[UUID];
                const promosInfo = window.ProductLineItems[UUID].promoInfo;

                if (promosInfo && promosInfo.length) {
                    promosInfo.forEach(function (promo, promoCount) {
                        let newPromo = promo; // eslint-disable-line
                        let $offerStatus;

                        newPromo.qty = promos.quantity;

                        if ($(element).hasClass('product-options') && $(element).parents('.product-tile').length) {
                            $offerStatus = $(element).parents('.product-tile').find('.offer-section').eq(promoCount).find('.offer-status');
                        } else if ($(element).hasClass('product-options') && $(element).parents('.product-suggestion').length) {
                            $offerStatus = $(element).parents('.product-suggestion').find('.offer-section').eq(promoCount).find('.offer-status');
                        } else {
                            $offerStatus = $(element).find('.offer-section').eq(promoCount).find('.offer-status');
                        }

                        const $modalOfferStatus =  $('.promo-modal-body[data-promo="'+ newPromo.ID +'"] .offer-status, .promo-landing-page[data-promo="'+ newPromo.ID +'"] .offer-status');
                        self.setupPromoMessage(newPromo, $offerStatus);
                        self.setupPromoMessage(newPromo, $modalOfferStatus);

                        if (!promotionsModal[newPromo.ID]) {
                            promotionsModal[newPromo.ID] = newPromo;
                        }
                    });
                } else if ($('.promo-modal').length) {
                    // Reset the offer status message to it's initial value
                    const offerStatus = $('.promo-modal .offer-status').attr('data-initial-message');
                    $('.promo-modal .offer-status').text(offerStatus);
                }
            } else if (UUID) {
                const $offerStatus = $(element).find('.offer-status');
                $offerStatus.empty();

                if ($('.promo-modal').length) {
                    // Reset the offer status message to it's initial value
                    const offerStatus = $('.promo-modal .offer-status').attr('data-initial-message');
                    $('.promo-modal .offer-status').text(offerStatus);
                }
            }
        });

        if ($('.promo-landing-page, .promo-modal').size() > 0) {
            if (Object.keys(promotionsModal).length) {
                var promoID = $('.promo-landing-page, .promo-modal').data('promo');
                for (var k in promotionsModal) {
                    const promo = promotionsModal[k];
                    if (promoID == promo.ID) {
                        self.updatePromoQuantity(promo);
                    }
                }
            } else {
                self.updatePromoQuantity();
            }
        }
    },

    /**
     * @function
     * @desc Creates the promotion message based on the given data
     * @param {Object} Object containing the promotion information
     * @param {jQuery} jQuery object containing the offer status DOM location
     */
    setupPromoMessage: function setupPromoMessage(promo, $offerStatus) {
        const msgGotIt = window.Resources.YOU_GOT_IT;
        const msgNear = window.Iconography.plus_regular + window.Resources.N_MORE_TO_GET_IT;
        const msgNearAgain = window.Iconography.plus_regular + window.Resources.N_MORE_TO_GET_IT_AGAIN;
        const customPromos = (promo.restrict) ? {[promo.ID] : promo.qty} : this.getBasketPromotionsQuantities();

        var msg = '',
            qty = customPromos[promo.ID],
            maxApp = promo.maxApp ? parseInt(promo.maxApp, 10) : 0,
            qualifiedPromos = 0,
            remaining = 0;

        if (promo.t === 'Combination') {
            let qualifies = true;

            while (qualifies && qualifiedPromos <= maxApp) {
                for (let i = 0; i < promo.groupReq.length; i++) {
                    var groupceil = promo.groupReq[i] + (promo.groupReq[i] * qualifiedPromos);

                    if (qty[i] < groupceil) {
                        qualifies = false;
                        remaining += groupceil - qty[i];
                    }
                }

                if (qualifies) {
                    qualifiedPromos++;
                } else if (promo.req == remaining) {
                    remaining = 0;
                }
            }
        } else {
            var requiredProducts = parseInt(promo.req, 10);

            qualifiedPromos = (qty / requiredProducts) >> 0;
            var ceil = (qty % requiredProducts === 0) ? qualifiedPromos * requiredProducts : requiredProducts + (qualifiedPromos * requiredProducts);
            remaining = ceil - qty;
        }

        const promoMet = (remaining === 0 || (maxApp > 0 && qualifiedPromos === maxApp));

        if (promoMet) {
            msg = msgGotIt;
            $('.offer-status-modal').addClass('finished');

        } else if (qualifiedPromos >= 1 && remaining !== 0) {
            msg = msgNearAgain.replace('{0}', remaining);
            $('.offer-status-modal').removeClass('finished');
        } else {
            msg = msgNear.replace('{0}', remaining);
            $('.offer-status-modal').removeClass('finished');
            $('.modal-footer').removeClass('modal-footer-complete');
        }

        if (msg !== '') {
            msg = '<span class="offer-msg">' + msg + '</span>';
        }

        $offerStatus.html(msg);
    },

    /**
     * @function
     * @desc Update promotion product quantities
     * @param {Object} Object containing the promotion information (optional)
     */
    updatePromoQuantity: function updatePromoQuantity(promo) {
        if (promo) {
            const promoQuantities = this.getBasketPromotionsQuantities(promo.ID);

            let qualifiedPromos = 0, // Number of times the promotion has been qualified for
                qualifiedGroups = 0, // Number of qualified groups in the promotion
                maxQualifiedGroupCount = 1, // Max qualification from all groups in the promotion
                selectedBonusProducts = 0, // Number of bonus products selected in the promotion
                maxBonusProducts = 0; // Max number of bonus products that can be selected

            if (promo.t === 'Combination') {
                // Loop through each promotion group to find the maximum qualfied group count. This gives us the correct denominator in our group counts.
                for (let i = 0; i < promo.groupReq.length; i++) {
                    const productQty = promoQuantities[i];
                    var productQtyRatio = Math.floor(productQty / promo.groupReq[i]);
                        //if qty increased by 1 item ratio logic will not work correctly
                    if (productQty > promo.groupReq[i] && productQtyRatio == 1) {
                        productQtyRatio++;
                    }

                    if (productQtyRatio > maxQualifiedGroupCount) {
                        maxQualifiedGroupCount = productQtyRatio;
                    }
                }

                var qualifiedPromotionCount = null;

                // Loop through each promotion group to find the required and current quantities
                for (let i = 0; i < promo.groupReq.length; i++) {

                    const $promoNavItems = $('.promo-step-list .promo-step-link:eq(' + i + ')', '.promo-modal, .promo-landing-page'),
                        $promoStepLink = $promoNavItems,
                        $promoStepQty = $promoStepLink.find('.promo-qty'),
                        $promoStepReqQty = $promoStepLink.find('.promo-req-qty');

                    const productQty = promoQuantities[i];
                    const promoRequiredQty = maxQualifiedGroupCount * promo.groupReq[i];

                    $promoStepReqQty.text(promoRequiredQty);
                    $promoStepQty.text(productQty);

                    if (productQty < promoRequiredQty) {
                        $promoStepLink.removeClass('completed');
                    } else if (productQty >= promoRequiredQty && (promoRequiredQty % productQty === 0)) {
                        $promoStepLink.addClass('completed');
                    }

                    var qualifiedPromotions = Math.floor(productQty / promo.groupReq[i]);
                    qualifiedGroups += (qualifiedPromotions >= 1) ? 1 : 0;
                    if (qualifiedPromotionCount === null) {
                        qualifiedPromotionCount = qualifiedPromotions;
                    } else if (qualifiedPromotions < qualifiedPromotionCount) {
                        qualifiedPromotionCount = qualifiedPromotions;
                    }
                }

                qualifiedPromos = Math.floor(qualifiedGroups / promo.groupReq.length);

                // Update the bonus product quantities based on the current discount line items
                const $promoBonusStepLinks = $('.promo-step-list .bonus-product', '.promo-modal, .promo-landing-page');

                for (var j in window.BonusDiscountLineItems) {
                    if (window.BonusDiscountLineItems[j].promotionID === promo.ID) {
                        selectedBonusProducts += window.BonusDiscountLineItems[j].bonusProducts.length;
                        maxBonusProducts += window.BonusDiscountLineItems[j].maxBonusItems;
                    }
                }

                // Update the select bonus product quantity display
                $promoBonusStepLinks.find('.promo-qty').text(selectedBonusProducts);

                // Make sure the max bonus products is at least the minimum value for one promotion qualification
                if (maxBonusProducts === 0) {
                    maxBonusProducts = $promoBonusStepLinks.attr('data-required-products');
                }

                $promoBonusStepLinks.find('.promo-req-qty').text(maxBonusProducts);

                const $promoBonus = $('.promo-step-list').find('.bonus-product-choice, .bonus-product');
                $promoBonus.find('.promo-req-qty').text(maxQualifiedGroupCount * $promoBonus.data('required-products'));

                if (qualifiedPromos >= 1) {
                    $promoBonusStepLinks.removeClass('disabled').addClass('unlocked');
                    $promoBonus.find('.promo-qty').text(qualifiedPromotionCount * $promoBonus.data('required-products'));
                } else {
                    $promoBonusStepLinks.addClass('disabled').removeClass('unlocked');
                    $promoBonus.find('.promo-qty').text(0);
                }
            }
        } else {
            // Clear all of the states of the promotions if there are none to update
            const $promoNavItems = $('.promo-step-list', '.promo-modal, .promo-landing-page'),
                $promoStepLinks = $promoNavItems.find('.promo-step-link'),
                $promoStepQtys = $promoStepLinks.find('.promo-qty'),
                $promoBonusStep = $promoStepLinks.closest('.bonus-product'),
                $promoBonusStepQtys = $promoBonusStep.find('.promo-qty');

            $promoStepLinks.removeClass('completed');
            $promoStepQtys.text(0);
            $promoBonusStepQtys.text(0);

            $promoBonusStep.addClass('disabled').removeClass('unlocked');
        }
    }
};

/**
 * @function
 * @desc Displays promotion in modal
 * @returns
 */
function displayPromoModal () {
    const promoID = $(this).attr('data-promo'),
        promotionName = $(this).data('promo-name'),
        promotionNameTxt = (promotionName && promotionName.length) ? promotionName : window.Resources.PROMO_MODAL_TITLE;

    console.log('promotionName', promotionName);
    console.log('promotionNameTxt', promotionNameTxt);

    if ($(this).hasClass('disabled')) {
        return;
    }

    if (window.User.zip) {
        const step = $(this).data('step');

        progress.show();

        dialog.open({
            url: window.Urls.promoModal,
            data: {
                promo: promoID,
                step:  step
            },
            options: {
                title: promotionNameTxt,
                dialogClass: 'promo-modal',
                buttons: [
                    {
                        text: window.Resources.PROMO_MODAL_CARRY_ON_BUTTON,
                        click: function() {
                            dialog.close();
                        }
                    }
                ],
                close: function() {
                    $('#wrapper').removeClass('open-modal')
                        .attr('aria-hidden', 'false');
                    if ($('#wrapper').hasClass('basket-updated')) {
                        page.refresh();
                    }
                }
            },
            callback: function () {
                $('.promo-modal').attr('data-promo', promoID)
                    .removeClass('deal-na');

                if ($('.promo-modal .promo-modal-body').length <= 0) {
                    $('.promo-modal').addClass('deal-na');
                }

                progress.hide();
                Promotions.addPromoMessages();

                $('.ui-dialog-buttonset button').removeClass('ui-button');

                // Tab show click handler to update next button
                $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {

                    if ($(e.target).hasClass('bonus-product-choice')) {

                        getBonusProducts($(e.target).attr('href'));

                        if ($('.ui-dialog-buttonset .add-to-cart-bonus').length <= 0) {
                            var atcButton = $('<button><span>'+ Resources.ADD_BASKET +'</span></button>');
                            atcButton.addClass('btn btn-primary btn-next add-to-cart-bonus');
                            $('.ui-dialog-buttonset').append(atcButton);
                        }
                    } else if ($(e.target).hasClass('bonus-product')) {

                        const promotionID = $('.promo-landing-page, .promo-modal').attr('data-promo');

                        var url = util.appendParamToURL(Urls.getBonusProductsConfirmation, 'promotionID', promotionID);

                        ajax.load({
                            url: url,
                            target: $($(e.target).attr('href'))
                        });
                    }
                });

                // Set visable tab based on selected step
                var $currentStep = $('.promo-step-list', '.promo-modal').data('selectedstep');

                if ($currentStep >= 0) {
                    const elementIndex = $currentStep - 1;
                    $('.promo-modal .promo-step-list .promo-step-link:eq(' + elementIndex + ')').tab('show');
                }
            }
        });
    } else {
        const util = require('./util');

        if (util.mediaBreakpointUp('md')) {
            $('.header-delivery-details').click();
            util.scrollBrowser(0);
        } else {
            $('.menu-toggle').click();
            $('.left-nav-minicart input[name="postcode"]').focus();
        }
    }
}

function getBonusProducts(target) {

    const promoID = $('.promo-landing-page, .promo-modal').attr('data-promo');

    var url = util.appendParamsToUrl(Urls.getBonusProducts, {
        promotionID: promoID,
        format: 'ajax',
        lazyLoad: 'false',
        promomodal: 'true',
        pageStart: 0,
        pageSize: 10,
        bonusProductsTotal: -1
    });

    var jqXHR = $.ajax({
        type: 'GET',
        cache: false,
        contentType: 'application/json',
        url: url
    });

    jqXHR.done(function (data) {
        $(target).html(data);

        var bonusProductsView = require('./bonus-products-view');
        bonusProductsView.initPromoModal();

        // Position the modal after loading the bonus products
        if (util.mediaBreakpointUp('md')) {
            dialog.$container.dialog('option', 'position', {my: 'center', at: 'center', of: window});
        } else {
            dialog.$container.dialog('option', 'position', {my: 'top', at: 'top', of: window});
        }
    });

    jqXHR.fail(function (xhr, textStatus) {
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        } else {
            window.alert(Resources.SERVER_CONNECTION_ERROR);
        }
    });
}

/**
 * @function
 * @desc Initializes the custom promotion event handlers
 * @returns
 */
function initializeEvents() {
    /**
     * @listener
     * @desc Listens for the click event on the offer section and opens a dialog for the promotion
     */
    $('body').on('click', '.offer-section', displayPromoModal);

    /**
     * @listener
     * @desc Listens for the click event on promo landing page steps and opens a dialog for the promotion
     */
    $('.promo-landing-page').on('click', '.promo-step-link', displayPromoModal);
}

module.exports = Promotions;
