'use strict';

var errorClass = 'error';
var successClass = 'valid';
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
        xx: /^.{4,10}$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    password: /^(?=.*[a-zA-Z0-9])(?=.*[A-Z])(?=.*[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@]).{8,}$/,
    date: /^(0?[1-9]|[12]\d|3[01])[\/](0?[1-9]|1[0-2])[\/](19|20)\d{2}$/
};
// global form validator settings
var settings = {
    errorClass: errorClass,
    errorElement: 'span',
    errorPlacement: function ($error, $element) {
        var $selectStyle = $element.parent('.select-style');
        if ($selectStyle.length) {
            $selectStyle.after($error);
            $selectStyle.removeClass(successClass).addClass(errorClass);
        } else if ($element.attr('type') === 'checkbox' || $element.attr('type') === 'radio' || $element.parents('.floatlabel').length) {
            var $label = $element.next('label');

            if ($label.length) {
                $label.after($error);
            } else {
                $element.after($error);
            }
        } else {
            $element.after($error);
        }
    },
    ignore: '.suppress, :hidden',
    onkeyup: false,
    onclick: function (element) {
        return $(element).is('select');
    },
    onfocusout: function (element) {
        if (!this.checkable(element) && !$(element).parents('.login-form').length) {
            this.element(element);
        }
    },
    success: function (error, element) {
        $(error).remove();
        var $selectStyle = $(element).parent('.select-style');
        if ($selectStyle.length) {
            $selectStyle.removeClass(errorClass).addClass(successClass);
        }
    },
    validClass: successClass
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('form').find('.country'),
        countryValue;
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        countryValue = window.SessionAttributes.REQUEST_COUNTRY;
    } else {
        countryValue = country.val().toLowerCase();
    }

    var rgx = regex.phone[countryValue];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * function
 * description Validates a given postal code against the countries postal code regex
 * param {String} value The postal code which will be validated
 * param {String} el The input field
 */
var validatePostalCode = function (value, el) {
    var postalCode = $(el).closest('form').find('.postal');
    var country = $(el).closest('form').find('.country');
    var rgx;
    var rgxXX = window.SessionAttributes.REQUEST_COUNTRY;
    if (country.length > 0 && !regex.postal[country.val().toLowerCase()]) {
        rgx = regex.postal[rgxXX];
    } else {
        if (country.length > 0) {
            rgx = regex.postal[country.val().toLowerCase()];
        } else {
            rgx = regex.postal[rgxXX];
        }
    }
    var isValid = rgx.test($.trim(value));

    if (isValid && (postalCode.length === 0 || postalCode.val().length === 0)) {
        return true;
    }

    return isValid;
};

/**
 * @function
 * @description Validates that a password meets requirments
 * @param {String} value The password to be validated
 */
var validatePassword = function (value) {
    var isValid = regex.password.test(value);
    return isValid;
};

/**
 * @function
 * @description Validates the given credit card number (Visa & MasterCard CC types are allowed.)
 * @param {String} value The credit card number field which will be validated
 * @param {String} el The input field
 */
var validateCreditCardNumber = function (value, el) {
    var cardType = $(el).closest('form').find('.cc-types');

    if (value != null && value.indexOf('*') >= 0) {return true;}

    switch (value.charAt(0)) {
        case '4' :
            cardType.val('Visa');
            break;
        case '2':
            cardType.val('MasterCard');
            break;
        case '5' :
            cardType.val('MasterCard');
            break;
        default:
            break;
    }

    var ccConfig,
        parent = $(el).parents('.field-wrapper');

    switch (cardType.val()) {
        case 'Visa':
            ccConfig = {lengths: '13,16', prefixes: '4', checkdigit: true}
            parent.attr('data-card-type', 'Visa');
            break;
        case 'MasterCard':
            ccConfig = {lengths: '16', prefixes: '51,52,53,54,55,22,23,24,25,26,27', checkdigit: true}
            parent.attr('data-card-type', 'MasterCard');
            break;
        default:
            break;
    }

    var cardNo = value.replace(/[\s-]/g, '');

    if (cardNo.length == 0) { return false; }

    var cardexp = /^[0-9]{13,19}$/;

    if (!cardexp.exec(cardNo)) { return false; }
    cardNo = cardNo.replace(/\D/g, '');

    if (ccConfig.checkdigit) {
        var checksum = 0,
            j = 1,
            calc;
        for (var i = cardNo.length - 1; i >= 0; i--) {
            calc = Number(cardNo.charAt(i)) * j;
            if (calc > 9) {
                checksum = checksum + 1;
                calc = calc - 10;
            }
            checksum = checksum + calc;
            if (j == 1) { j = 2 } else { j = 1 }
        }

        if (checksum % 10 != 0) { return false; } // not mod10
    }

    var lengthValid = false,
        prefixValid = false,
        prefix = new Array(),
        lengths = new Array();

    prefix = ccConfig.prefixes.split(',');
    for (var l = 0; l < prefix.length; l++) {
        var exp = new RegExp('^' + prefix[l]);
        if (exp.test(cardNo)) prefixValid = true;
    }

    if (!prefixValid) { return false; }

    lengths = ccConfig.lengths.split(',');
    for (var k = 0; k < lengths.length; k++) {
        if (cardNo.length == lengths[k]) lengthValid = true;
    }

    if (!lengthValid) { return false; }
    return true;
};

/**
 * @function
 * @description Validates a date to be used in a date picker
 * @param {String} value The date to be validated
 */
var validateDatePicker = function (value, el) {
    var isValid = regex.date.test(value);
    var isOptional = this.optional(el);
    return isOptional || isValid;
};

/**
 * @function
 * @description Validates email addresses
 * @param email
 * @returns {Boolean}
 */
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * @function
 * @description Validates the given credit card expiration date
 */
var validateCreditCardExpiration = function () {
    var currentYear = (new Date()).getFullYear();
    var currentMonth = (new Date()).getMonth() + 1;
    var $expirationYear = $('select[name$=_expiration_year]');
    var $expirationMonth = $('select[name$=_expiration_month]');
    var ccExpirationMonth = parseInt($expirationMonth.val(), 10);
    var ccExpirationYear = parseInt($expirationYear.val(), 10);

    var isValid = (isNaN(ccExpirationYear) || isNaN(ccExpirationMonth)) ||
        (ccExpirationYear === currentYear && ccExpirationMonth >= currentMonth) ||
        ccExpirationYear > currentYear;

    if (isValid) {
        $expirationMonth.removeClass('error');
        $($expirationMonth, $expirationYear).parent().removeClass('expireError');
        $('.month-year').find('.form-caption.error-message, span.error').removeClass('.error-message').html('').hide();

        return true;
    } else {
        $expirationMonth.addClass('error');
        $($expirationMonth, $expirationYear).parent().addClass('expireError');
        $('.month').find('.form-caption').html(Resources.INVALID_EXPIRATION).addClass('error-message').show();
    }

    return false;

};


/**
 * Add credit card expiration month method to jQuery validation plugin.
 * Text fields must have 'expiration-year' css class to be validated
 */
$.validator.addMethod('expiration-year', validateCreditCardExpiration, null);

/**
 * Add credit card expiration month method to jQuery validation plugin.
 * Text fields must have 'expiration-month' css class to be validated
 */
$.validator.addMethod('expiration-month', validateCreditCardExpiration, null);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add password validation method to jQuery validation plugin.
 * Text fields must have 'password' css class to be validated as a password
 */
$.validator.addMethod('newpassword', validatePassword, Resources.INVALID_PASSWORD);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as a postal code
 */
$.validator.addMethod('postal', validatePostalCode, Resources.INVALID_POSTALCODE);

/**
 * Add credit card number validation method to jQuery validation plugin.
 * Text fields must have 'ccard-number' css class to be validated
 */
$.validator.addMethod('ccard-number', validateCreditCardNumber, Resources.VALIDATE_VISA_MS_CARD);

/**
 * Add date validation method to jQuery validation plugin.
 * Text fields must have 'input-date' css class to be validated as a date
 */
$.validator.addMethod('input-date', validateDatePicker, Resources.INVALID_DATE);

/**
 * Add email field validation to jQuery validation plugin.
 * Text fields must have 'email' class to be validated as an email
 */
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).addClass('jqvalidate').validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).addClass('jqvalidate').validate(this.settings);
    }
};

module.exports = validator;
