'use strict';

var _ = require('lodash'),
    smallBreakpoint = 320,
    mediumBreakpoint = 480,
    largeBreakpoint = 768,
    desktopBreakpoint = 1024,
    maxBreakpoint = 1280;

var util = {
    /**
     * @desc Media breakpoints that are used throughout the Javascript
     */
    breakpoints: {
        xs: smallBreakpoint,
        sm: mediumBreakpoint,
        md: largeBreakpoint,
        lg: desktopBreakpoint,
        xl: maxBreakpoint,
        'mobile-menu': largeBreakpoint
    },

    /**
     * @function
     * @description Returns either an object with all of the available breakpoints or a specific viewport based on the given size
     * @param {string} size The viewport to return
     * @param {string} breakpoints A custom breakpoints object
     */
    getViewports: function (size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;

        if (typeof size !== 'undefined') {
            var viewport = bps[size];

            if (viewport) {
                return viewport;
            } else {
                window.console.error('Unexpected viewport size given in util.getViewports');
                throw 'Unexpected viewport size given in util.getViewports';
            }
        } else {
            return breakpoints;
        }
    },

    /**
     * @function
     * @description Returns the current viewport name (ex: 'medium') or 'max' if the current window is larger than any defined viewport width
     */
    getCurrentViewport: function () {
        var w = window.innerWidth;
        var viewports = util.getViewports();
        //traverse the object from small up to desktop, and return the first match
        _.each(viewports, function (value, name) {
            if (w <= value) {
                return name;
            }
        });
        return 'max';
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key' || field === 'type') {
                continue;
            }
            if (field === 'displayValue') {
                $form.find('input[id$="edit_addressId"]').val(address[field]);
                $form.find('input[name$="_customerAddressID"]').val(address[field]);
            }
            if (field === 'postalCode') {
                $form.find('input[id$="edit_zipCode"]').val(address[field]);
            }

            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field])
                .addClass('text-entered');

            if (field === 'city'){
                $form.find('select[id$="edit_city"]').val(address[field]);
            }
            if (field === 'stateCode'){
                $form.find('select[id$="edit_state"]').val(address[field]);
            }
            
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode).addClass('text-entered');
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({scrollTop: xLocation}, 500);
    },

    /**
     * @function
     * @desc Determines if the device that is being used is mobile
     * @returns {Boolean}
     */
    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var    idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    /**
     * Executes a callback function when the user has stopped resizing the screen.
     *
     * @param   {function}  callback
     * @var     obj         timeout
     *
     * @return  {function}
     */
    smartResize: function (callback) {
        var timeout;

        $(window).on('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 100);
        }).resize();

        return callback;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints (optional)
     */
    mediaBreakpointUp: function (size, breakpoints) {
        const breakpoint = this.getViewports(size, breakpoints);
        const mediaQuery = window.matchMedia('(min-width: '+ breakpoint +'px)');
        return mediaQuery.matches;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointDown: function (size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;
        const nextSize = this.getNextObjectKey(bps, size);

        if (typeof nextSize === 'string') {
            const breakpoint = this.getViewports(nextSize, breakpoints) - 1;
            const mediaQuery = window.matchMedia('(max-width: '+ breakpoint +'px)');
            return mediaQuery.matches;
        } else {
            return true;
        }
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media queries based on the given params
     * @param {string} sizeMin - Min breakpoint to use for the media query
     * @param {string} sizeMax - Max breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointBetween: function (sizeMin, sizeMax, breakpoints) {
        const min = this.mediaBreakpointUp(sizeMin, breakpoints);
        const max = this.mediaBreakpointDown(sizeMax, breakpoints);

        return min && max;
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointOnly: function (size, breakpoints) {
        return this.mediaBreakpointBetween(size, size, breakpoints);
    },

    /**
     * @function
     * @desc Retrieves the next key in the object or null if it doesn't exist
     * @returns {string}|{null}
     */
    getNextObjectKey: function (obj, key) {
        const keys = Object.keys(obj);
        const nextIndex = keys.indexOf(key) + 1;

        if (keys.length > nextIndex) {
            return keys[nextIndex];
        } else {
            return null;
        }
    },

    /**
     * @function
     * @desc Retrieves the util breakpoints object
     * @returns {object}
     */
    getBreakpoints: function () {
        return this.breakpoints;
    },


    /**
     * @function
     * @desc Generate a unique number. Useful for tagging unique jquery selectors or namespacing js events
     * @returns {object}
     */
    guid: function () {
        var timestamp = new Date().getUTCMilliseconds();
        return timestamp;
    },

    /**
     * @function
     * @desc Detect click, touch, or focus outside an element and execute a callback function
     * @param {jQuery} $parentTrigger - the element or wrapper outside of which to detect a click
     * @param {function} callBack - The callback function to execute on detection of a clickOutside action
     * @returns {string} eventID - the created event name, ex: "click.outside321 touchstart.outside321 focusin.outsidd321"
     */
    clickOutside: function ($parentTrigger, callBack) {

        // get a unique ID string
        var guid = util.guid();

        // assign a specific namespace to a click/touchstart/focus handler on the document,
        // so we can unbind it later without unbinding all click/touchstart/focus document events.
        var clicknamespace = 'click.outside' + guid + ' touchstart.outside'+ guid + ' focusin.outside'+ guid;

        // Wrapping this in a zero-time setTimeout allows it to ignore the initial event whose handler triggered this function.
        // otherwise this event could register as an immediate "click outside" and trigger this callBack function
        setTimeout(function () {
            $(document).on(clicknamespace, function(e) {
                if ($(e.target).parents().is($parentTrigger) == false) {
                    if (typeof callBack === 'function') {
                        // execute the callback function, if defined
                        callBack();
                    }
                    // unbind this event after it's served its purpose, so we don't have these stack up
                    $(document).off(clicknamespace);
                }
            });
        }, 0);

        //return the created event name, in case a different trigger needs to unbind it.
        return clicknamespace;
    },

    /**
     * @function
     * @desc Given a valid selector string or jQuery object, returns a jQuery object. Returns null is the given string is not a valid selector.
     * @param {String || jQuery} target - should represent a selector for a jQuery object, or should be a jQuery object itself. Ex: '.myselector' or $('.myselector')
     */
    getTarget: function (target) {
        if (_.isString(target)) {
            return $(target);
        } else if (target instanceof jQuery) {
            return target;
        }
        return null;
    },

    /**
     * Determinate is empty object or not
     * @param {Object} incoming object
     * @return {Boolean} true - empty, false - otherwise
     */
    isEmpty: function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
};

module.exports = util;
