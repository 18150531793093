'use strict';

var dialog = null, // require('./dialog'),
    page = require('./page'),
    util = require('./util'),
    favouritewidget = require('./pages/favouritewidget');

var selectedList = [];
var selectedListState = [];
var maxItems = 1;
var bliUUID = '';

/**
 * @private
 * @function
 * description Gets a list of bonus products related to a promoted product
 */
function getBonusProducts() {
    var bonusproducts = [];

    var i, len;
    for (i = 0, len = selectedList.length; i < len; i++) {
        var p = {
            pid: selectedList[i].pid,
            qty: selectedList[i].qty,
            options: {}
        };
        var a, alen, bp = selectedList[i];
        if (bp.options) {
            for (a = 0, alen = bp.options.length; a < alen; a++) {
                var opt = bp.options[a];
                p.options = {optionName:opt.name, optionValue:opt.value};
            }
        }
        bonusproducts.push({product:p});
    }
    return {bonusproducts: bonusproducts};
}

var selectedItemTemplate = function (data) {
    var attributes = '';
    for (var attrID in data.attributes) {
        var attr = data.attributes[attrID];
        attributes += '<li data-attribute-id="' + attrID + '">\n';
        attributes += '<span class="display-name">' + attr.displayName + '</span>: ';
        attributes += '<span class="display-value">' + attr.displayValue + '</span>\n';
        attributes += '</li>';
    }
    attributes += '<li class="item-qty">\n';
    attributes += '<span class="display-value">' + data.qty + '</span>';
    return [
        '<li class="selected-bonus-item" data-uuid="' + data.uuid + '" data-pid="' + data.pid + '">',
        '<i class="remove-link fa fa-remove" href="#"></i>',
        '<div class="item-name">' + data.name + '</div>',
        '<ul class="item-attributes">',
        attributes,
        '<ul>',
        '<li>'
    ].join('\n');
};

/**
 * @private
 * @function
 * @description Updates the summary page with the selected bonus product
 */
function updateSummary() {
    var $bonusProductList = $('#bonus-product-list');
    if (!selectedList.length) {
        $bonusProductList.find('li.selected-bonus-item').remove();
        $('.add-to-cart-bonus').attr('disabled', 'disabled');
    } else {
        var ulList = $bonusProductList.find('ul.selected-bonus-items').first();
        var i, len;
        for (i = 0, len = selectedList.length; i < len; i++) {
            var item = selectedList[i];
            var li = selectedItemTemplate(item);
            $(li).appendTo(ulList);
        }
        $('.add-to-cart-bonus').removeAttr('disabled');
    }

    // get remaining item count
    var sumItems = 0;
    $('.selected-bonus-item').each(function() {
        sumItems += Number($(this).find('.item-qty .display-value').text());
    });
    var remain = maxItems - sumItems;
    if (remain <= 0) {
        $bonusProductList.find('.select-bonus-item').not('.selected').attr('disabled', 'disabled');
    } else {
        $bonusProductList.find('.select-bonus-item').removeAttr('disabled');
    }
}

var $firstLoad = true;

function initializeGrid () {
    var $bonusProductList = $('#bonus-product-list'),
        bliData = $bonusProductList.data('line-item-detail');
    maxItems = bliData.maxItems;
    bliUUID = bliData.uuid;

    $bonusProductList.on('click', '.select-bonus-item', function (e) {
        e.preventDefault();

        if ($(this).hasClass('selected')) {
            var $current = $(this).parents('.bonus-product-item').data('productuuid');

            $('.selected-bonus-items').find('[data-uuid="' + $current  + '"] .remove-link')
                .trigger('click');
            $(this).removeClass('selected')
                .text(Resources.SELECT_BONUS_PRODUCT)
                .switchClass('btn-primary', 'btn-dark');
            return;
        }

        var sumItems = 0;
        $('.selected-bonus-item').each(function() {
            sumItems += Number($(this).find('.item-qty .display-value').text());
        });

        if (selectedList.length >= maxItems) {
            $bonusProductList.find('.select-bonus-item').not('.selected').attr('disabled', 'disabled');
            return;
        }

        var form = $(this).closest('.bonus-product-form'),
            uuid = form.find('input[name="productUUID"]').val(),
            qtyVal = form.find('input[name="Quantity"], select[name="Quantity"]').val(),
            qty = (isNaN(qtyVal)) ? 1 : (+qtyVal);
        if (qty > maxItems - sumItems) {
            $(this).attr('disabled', 'disabled');
            return;
        }

        var product = {
            uuid: uuid,
            pid: form.find('input[name="pid"]').val(),
            qty: qty,
            name: form.find('.product-name').text(),
            attributes: form.find('.product-variations').data('attributes'),
            options: []
        };

        var optionSelects = form.find('.product-option');

        optionSelects.each(function () {
            product.options.push({
                name: this.name,
                value: $(this).val(),
                display: $(this).children(':selected').first().html()
            });
        });

        selectedList.push(product);
        $('#bonus-product-list li').remove('.selected-bonus-item');
        $(this).addClass('selected')
            .text(Resources.SELECTED_BONUS_PRODUCT)
            .switchClass('btn-dark', 'btn-primary');
        updateSummary();
    }).on('click', '.remove-link', function (e) {
        e.preventDefault();
        var container = $(this).closest('.selected-bonus-item');
        if (!container.data('uuid')) { return; }

        var uuid = container.data('uuid');
        var i, len = selectedList.length;
        for (i = 0; i < len; i++) {
            if (selectedList[i].uuid === uuid) {
                selectedList.splice(i, 1);
                break;
            }
        }
        $('#bonus-product-list li').remove('.selected-bonus-item');
        updateSummary();
    })
    .on('click', '.add-to-cart-bonus', function (e) {
        e.preventDefault();
        $(this).addClass('clicked');
        var url = util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID: bliUUID});
        var bonusProducts = getBonusProducts();
        if (bonusProducts.bonusproducts[0].product.qty > maxItems) {
            bonusProducts.bonusproducts[0].product.qty = maxItems;
        }
        // make the server call
        $.ajax({
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            url: url,
            data: JSON.stringify(bonusProducts)
        })
        .done(function () {
            // success
            if (window.pageContext.ns == 'cart') {
                page.refresh();
            } else {
                page.redirect(Urls.cartShow);
            }
        })
        .fail(function (xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        })
        .always(function () {
            dialog.close();
        });
    })
    .on('click', '#more-bonus-products', function (e) {
        e.preventDefault();
        var uuid = $('#bonus-product-list').data().lineItemDetail.uuid;

        //get the next page of choice of bonus products
        var lineItemDetail = JSON.parse($('#bonus-product-list').attr('data-line-item-detail'));
        lineItemDetail.pageStart = lineItemDetail.pageStart + lineItemDetail.pageSize;
        $('#bonus-product-list').attr('data-line-item-detail', JSON.stringify(lineItemDetail));

        var url = util.appendParamsToUrl(Urls.getBonusProducts, {
            bonusDiscountLineItemUUID: uuid,
            format: 'ajax',
            lazyLoad: 'true',
            pageStart: lineItemDetail.pageStart,
            pageSize: $('#bonus-product-list').data().lineItemDetail.pageSize,
            bonusProductsTotal: $('#bonus-product-list').data().lineItemDetail.bpTotal
        });

        $.ajax({
            type: 'GET',
            cache: false,
            contentType: 'application/json',
            url: url
        })
        .done(function (data) {
            //add the new page to DOM and remove 'More' link if it is the last page of results
            $('#more-bonus-products').before(data);
            if ((lineItemDetail.pageStart + lineItemDetail.pageSize) >= $('#bonus-product-list').data().lineItemDetail.bpTotal) {
                $('#more-bonus-products').remove();
            }
        })
        .fail(function (xhr, textStatus) {
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    });

    var $cartItems = $bonusProductList.find('.selected-bonus-item');

    $cartItems.each(function () {
        var $selectedItem = $('.bonus-product-item[data-productuuid="' + $(this).data('uuid') + '"]'),
            $selectBonusItem = $selectedItem.find('.bonus-product-form .select-bonus-item');
        if ($firstLoad) {
            $(this).remove();
            $selectBonusItem.trigger('click');
        } else {
            $selectBonusItem.addClass('selected')
            .text(Resources.SELECTED_BONUS_PRODUCT)
            .switchClass('btn-dark', 'btn-primary');
        }
    });

    $firstLoad = false;
    selectedListState = selectedList.slice();

    var sumItems = 0;
    $('.selected-bonus-item').each(function() {
        sumItems += Number($(this).find('.item-qty .display-value').text());
    });

    if (sumItems >= maxItems) {
        $bonusProductList.find('.select-bonus-item').not('.selected').attr('disabled', 'disabled');
    }
}

var bonusProductsView = {
    /**
     * @function
     * @description Open the list of bonus products selection dialog
     */
    show: function (url, title) {

        dialog.open({
            url: url,
            options: {
                title: title,
                dialogClass: 'bonus-modal mobile-backarrow',
                showMobileBackArrow: true,
                open: function () {
                    initializeGrid();
                    favouritewidget.initAddToFavourite();

                    $('.bonus-modal').on('click', '.no-thanks', function () {
                        dialog.close();
                    });
                },
                close: function () {
                    if (!$('.add-to-cart-bonus').hasClass('clicked')) {
                        selectedList = selectedListState.slice();
                    }
                }
            }
        });
    },
    /**
     * @function
     * @description Open bonus product promo prompt dialog
     */
    loadBonusOption: function () {
        var bonusDiscountContainer = $('.bonus-discount-container');
        if (!bonusDiscountContainer.length) { return; }
        // get the uuid from minicart, then trash html

        var uuid = bonusDiscountContainer.data('lineitemid'),
            title = bonusDiscountContainer.data('dialogtitle'),
            url = util.appendParamsToUrl(Urls.getBonusProducts, {
                bonusDiscountLineItemUUID: uuid,
                source: 'bonus',
                format: 'ajax',
                lazyLoad: 'false',
                pageStart: 0,
                pageSize: 10,
                bonusProductsTotal: -1
            });

        bonusDiscountContainer.remove();
        bonusProductsView.show(url, title);
    }
};

module.exports = bonusProductsView;
