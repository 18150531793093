'use strict';

var $loader = null;
var $target = null;

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 * @param {Boolean} adjustContainerPosition Default value is true, allowing the target's css 'position' rule to
    temporarily be adjusted to 'relative' while the overlay is active. Passing a value of false disallows this.
 */
var show = function (container, adjustContainerPosition) {
    hide(); // just in case, remove any other progress overlays. This isn't built to support multiple.

    $target = (!container || $(container).length === 0) ? $('body') : $(container);
    $loader = $loader || $('.loader');

    if ($loader.length === 0) {
        $loader = $('<div/>').addClass('loader')
            .append($('<div/>').addClass('loader-indicator'), $('<div/>').addClass('loader-bg'));
    }
    if (adjustContainerPosition !== false) {
        $target.addClass('pos-relative').data('position-adjusted', true);
    }
    return $loader.appendTo($target).show();
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function () {
    if ($loader) {
        $loader.hide();
    }
    if ($target && $target.length && $target.data('position-adjusted')) {
        $target.removeClass('pos-relative');
    }
};

exports.show = show;
exports.hide = hide;
