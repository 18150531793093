'use strict';


const progress = require('./progress'),
    promos = require('./promos'),
    favouritewidget = require('./pages/favouritewidget');

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        if (!$(this).hasClass('active')) {
            $('.search-result-content').toggleClass('wide-tiles');
            $('.toggle-grid').removeClass('active');
            $(this).addClass('active');
        }
    });
}

$(document).on('toastNotify', function(event, type){
    if(type === 'removed'){
        $('.toastMessageContainer i').removeClass('fa-check-circle').addClass('fa-times-circle');
        $('.toastMessage-text').text($('.toastMessage').data('messageRemoved'));
    } else if (type === 'added'){
        $('.toastMessageContainer i').removeClass('fa-times-circle').addClass('fa-check-circle');
        $('.toastMessage-text').text($('.toastMessage').data('messageAdded'));
    }

    $('.toastMessage').addClass('toastVisible');
    setTimeout(function(){
        $('.toastMessage').removeClass('toastVisible');
    }, 2000);
});
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    gridViewToggle();
    favouritewidget.initAddToFavourite();

    $(function() {
        $('.pdp-link .link').each(function() {
            var productTitle = $(this).text();
            var titleLength = productTitle.length;
            if(titleLength > 50) {
                $(this).text($(this).text().substring(0,49)+'...');
            }
        })
    })

    /**
     * @listener
     * @desc Listens for the click event on the product tile add-to-cart button
     */
    $('body').off('click.producttile').on('click.producttile', '.tile-add-to-cart', function (e) {
        e.preventDefault();
        if (!getCookie('dw_shippostalcode')) {
            $('#popUpCep').slideDown();
            $('#popUpCep').modal('show'); //MOSTRA O MODAL DE CEP
        } else {
            const productTile = $(this).parents('.product-tile');
            const suppressMinicart = $(this).parents('.promo-modal').length > 0 && !$('.pt_beforeyougo').length;
            addToCart(productTile, suppressMinicart);
        }
    });

    /**
     * @listener
     * @desc Listens for the basketupdate event from the mini cart and updates the product tile
     */
    $(document).off('basketupdate.producttile').on('basketupdate.producttile', function (e) {
        if (e.source === 'minicart' && e.quantity === 0) {
            const productTile = $('.product-tile[data-itemid="' + e.pid + '"]');

            // Product was removed from the mini cart
            const jqXHR = $.ajax({
                url: window.Urls.hitTile,
                data: {
                    pid: e.pid
                }
            });

            // Show the progress indicator over the product tile
            progress.show(productTile);

            jqXHR.done(function (html) {
                productTile.replaceWith(html);
                // TODO: Re-initialize the product ratings when implemented

                $(document).trigger('toastNotify', 'removed');
            });

            // Make sure to remove the progress indicator after the Ajax call
            jqXHR.always(function () {
                progress.hide();
            });
        }
    });
}

/**
 * @function
 * @desc Adds the given product ID to the cart
 * @param {jQuery} productTile - Product tile used to get the product ID
 */
function addToCart(productTile, suppressMinicart) {
    if (productTile){
        const pid = productTile.find('.product-options').attr('data-pid');

        const jqXHR = $.ajax({
            method: 'post',
            url: window.Urls.tileAddToCart,
            data: {
                pid: pid,
                Quantity: 1
            }
        });

        // Show the progress indicator over the product tile
        progress.show(productTile);

        jqXHR.done(function (html) {
            $('.product-tile[data-itemid="' + pid + '"]').replaceWith(html);

            // TODO: Re-initialize the product ratings when implemented
            $(document).trigger('toastNotify', 'added');

            $(document).trigger({
                type: 'basketupdate',
                pid: pid,
                quantity: 1,
                source: 'tile',
                suppress: suppressMinicart

            });

        });

        // Make sure to remove the progress indicator after the Ajax call
        jqXHR.always(function () {
            progress.hide();
        });

        return jqXHR;
    }
}

exports.init = function () {
    initializeEvents();
    promos.init();
    promos.addPromoMessages();
};

exports.addToCart = addToCart;
exports.initializeEvents = initializeEvents;
